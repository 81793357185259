<template>
  <el-dialog
    :visible="dialogVisible"
    @close="closeDialog('ruleForm')"
    :show-close="false"
    @open="open"
  >
    <div slot="title" class="left_top">
      <div class="left_icon">
        <span></span>
        <span>{{title}}</span>
      </div>
    </div>
    <el-form
      label-position="top"
      width="900px"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="ruleForm"
      label-width="80px"
    >
      <!-- <el-divider content-position="left">区域选择</el-divider> -->
      <el-form-item label="签署人" prop="name">
        <el-select
          v-model="ruleForm.name"
          filterable
          :placeholder="'请输入签署人'"
          :disabled="disabledShow"
          style="width: 100%"
          remote
          reserve-keyword
          :remote-method="inputChange"
          @change="userChange"
        >
          <el-option
            v-for="item in userList"
            :key="item.code"
            :label="item.name "
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色" prop="roles">
        <el-input v-model="ruleForm.roles" disabled placeholder="请输入角色"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="tel">
        <el-input v-model="ruleForm.tel" disabled placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="授权印章" prop="radio">
        <el-radio-group v-model="ruleForm.radio" :disabled="disabledShow" @input="radioChange">
          <el-radio v-for="item in imgList" :key="item.sealId" :label="item.sealId">
            <!-- <img class="img_class" : alt /> -->
            <el-popover placement="right-start" trigger="hover">
              <img :src="item.sealImageDownloadUrl" alt srcset style="width: 300px" />
              <img
                slot="reference"
                trigger="hover"
                class="img_class"
                :src="item.sealImageDownloadUrl"
                alt
              />
              <!-- <span
                slot="reference"
                trigger="hover"
                style="cursor: pointer;color:cornflowerblue"
                v-if="item.sealImageDownloadUrl"
              >{{item.sealBizTypeDescription}}</span>-->
            </el-popover>
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="授权有效期" prop="authorizationData">
        <el-date-picker
          v-model="ruleForm.authorizationData"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" v-if="title!='编辑签署人'" @click="submitForm('ruleForm')">确 定</el-button>
      <el-button type="primary" v-else @click="gosubmitForm('ruleForm')">编 辑</el-button>
      <el-button @click="closeDialog('ruleForm')">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { api } from '/src/api/base';
export const staffList = api()('staff.list.json');
export const contractSingerSealIdList = api()('contractSinger.sealId.list.json');
export const contractSingerSingerAuthUrlList = api()('contractSinger.singerAuthUrlList.json');
export const contractSingerInfo = api()('contractSinger.info.json');
export const contractSingerUpdateExpireUrl = api()('contractSinger.updateExpireUrl.json');



export default {
  components: {

  },
  data () {
    return {
      ruleForm: {

        name: '',
        roles: '',
        tel: '',
        radio: '',
        authorizationData: '',
        sealUrl: ''
      },
      rules: {
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        authorizationData: [{ required: true, message: '请选择授权有效期', trigger: 'blur' }],
        radio: [{ required: true, message: '请选择授权公章印章', trigger: 'blur' }]
      },
      newCode: this.code,
      imgList: [],
      userList: [],
      provinceList: [],
      cityList: [],
      disabledShow: false
    };
  },
  props: {
    dialogVisible: Boolean,
    title: String,
    code: String
  },
  created () {


  },
  methods: {
    radioChange (val) {
      console.log(val);
      this.ruleForm.sealUrl = this.imgList.filter(it => it.sealId === val)[0].sealImageDownloadUrl
    },
    open () {
      if (this.title === '编辑签署人') {
        this.getInfo()
        this.disabledShow = true
        this.getContractImg()

      }
      else {
        this.disabledShow = false


        this.getContractImg()
      }



    },
    getInfo () {
      const params = {
        code: this.code
      };
      contractSingerInfo(params).then((res) => {
        console.log(res);
        this.ruleForm.name = res.user.code
        this.ruleForm.radio = res.sealId
        this.ruleForm.tel = res.user.username
        this.ruleForm.roles = res.role.name
        this.ruleForm.authorizationData = [res.effectiveTime, res.expireTime]
        console.log(this.ruleForm.authorizationData);
      });
    },
    gosubmitForm (formName) {
      console.log(this.ruleForm.authorizationData, 2222);

      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = {
            code: this.code,
            effectiveTime: this.ruleForm.authorizationData[0],
            expireTime: this.ruleForm.authorizationData[1],
            redirectUrl: window.location.href
          };
          contractSingerUpdateExpireUrl(params).then((res) => {
            this.disabledShow = false
            window.location.href = res;

            this.closeDialog(formName)
          });
        }
      })

    },
    getContractImg () {
      const params = {
      };
      contractSingerSealIdList(params).then((res) => {
        this.imgList = res

      });
    },

    getstaffList (val) {
      console.log(this.ruleForm.name);
      const params = {
        f_like_name: val,
        isAuthenticated: true,
        pageNumber: 1,
        pageSize: 10

      };
      staffList(params).then((res) => {

        this.userList = res.list

      });
    },
    inputChange (val) {

      this.getstaffList(val)

    },
    userChange (val) {
      const a = this.userList.filter(it => it.code === val)[0]
      this.ruleForm.tel = a.username
      this.ruleForm.roles = a.role.name
      console.log(a);
    },
    submitForm (formName) {
      console.log(this.ruleForm.authorizationData, 22222222222);

      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = {
            userCode: this.ruleForm.name,
            sealId: this.ruleForm.radio,
            sealUrl: this.ruleForm.sealUrl,
            effectiveTime: this.ruleForm.authorizationData[0],
            expireTime: this.ruleForm.authorizationData[1],
            redirectUrl: window.location.href
          };
          contractSingerSingerAuthUrlList(params).then((res) => {
            window.location.href = res;
            this.closeDialog(formName)
          });
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    closeDialog (formName) {
      this.resetForm(formName)
      this.newCode = ''
      this.$emit('update:dialogVisible', false);
      this.$emit('func', this.dialogVisible);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
.img_class {
  width: 50px;
  height: 50px;
}
</style>
